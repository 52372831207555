import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-match-phase',
  templateUrl: './match-phase.component.html',
  styleUrls: ['./match-phase.component.scss']
})
export class MatchPhaseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
