import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-foundationgridx',
  templateUrl: './foundationgridx.component.html',
  styleUrls: ['./foundationgridx.component.scss']
})
export class FoundationGridXComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
