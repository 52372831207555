
export enum SkillCategory {
  NORMAL = 'NORMAL',
  MONSTER = 'MONSTER',
  STRONG_MONSTER = 'STRONG_MONSTER',
  ELITE_MONSTER = 'ELITE_MONSTER',
  JOB = 'JOB',
  REACTION = 'REACTION',
  MOVEMENT = 'MOVEMENT',
  EQUIPMENT = 'EQUIPMENT',
  SUPPORT = 'SUPPORT',
  ENTRY = 'ENTRY',
  LEGENDARY = 'LEGENDARY',
  PRESTIGE = 'PRESTIGE',
  NONE = 'NONE'
}
