import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-foundationgridcontainer',
  templateUrl: './foundationgridcontainer.component.html',
  styleUrls: ['./foundationgridcontainer.component.scss']
})
export class FoundationGridContainerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
