import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-phase',
  templateUrl: './loading-phase.component.html',
  styleUrls: ['./loading-phase.component.scss']
})
export class LoadingPhaseComponent implements OnInit {

  public constructor() { }

  public ngOnInit(): void {
  }

}
