
export enum Allegiance {
  RED = 'red',
  BLUE = 'blue',
  GREEN = 'green',
  YELLOW = 'yellow',
  WHITE = 'white',
  BLACK = 'black',
  PURPLE = 'purple',
  BROWN = 'brown',
  CHAMPION = 'champion',
  LEFT = 'left',
  RIGHT = 'right',
  RANDOM = 'random',
  NONE = 'none'
}
